import React from 'react';

const Component404 = () => {
    return (
        <main>
            <p>Página no encontrada</p>
        </main>
    );
};

export default Component404;