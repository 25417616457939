import React from 'react';
import comedor from '../../img/piso/comedor.jpg'
import './index.scss';

const Detail = () => {
    const item = {
        id: 1,
        title: 'Piso en Linares',
        description: 'Piso en venta en Linares - Jaén, piso para reformar de 88 m2 distribuidos en salón, terraza, cocina amueblada, dos dormitorios y un cuarto de baño. Suelos de gres, ventanas de aluminio lacado en blanco, persianas de pvc. 2º piso sin ascensor. Muy bien situado con todos los servicios próximos. Visita sin compromiso.',
        habitaciones: 2,
        planta: 2,
        metros: 88,
        ascensor: false,
        baño: 1,
        terraza: true,
        balcon: true,
        tipo: '2ª mano / Reformar',
        CEnergetica: 'E (IPE no indicado)',
        precio: 39000,
   }
    return (
        <main className="Detail">
            <h2>{item.title}</h2>
            <div className="ImageCarroussel">
                <img src={comedor} alt="Comedor de la vivienda" />
            </div>
            <p>{item.description}</p>
            <h4>Características</h4>
            <table>
                <tbody>
                    <tr>
                        <td>Metros Cuadrados: </td>
                        <td>{item.metros} m<span className="square">2</span></td>
                    </tr>
                    <tr>
                        <td>Habitaciones: </td>
                        <td>{item.habitaciones} hab</td>
                    </tr>
                    <tr>
                        <td>Planta: </td>
                        <td>{item.planta}ª</td>
                    </tr>
                    <tr>
                        <td>Ascensor: </td>
                        <td>{item.ascensor?"Sí":"No"}</td>
                    </tr>
                    <tr>
                        <td>Baños: </td>
                        <td>{item.baño}</td>
                    </tr>
                    <tr>
                        <td>Terraza: </td>
                        <td>{item.terraza?"Sí":"No"}</td>
                    </tr>
                    <tr>
                        <td>Balcon: </td>
                        <td>{item.balcon?"Sí":"No"}</td>
                    </tr>
                    <tr>
                        <td>Estado: </td>
                        <td>{item.tipo}</td>
                    </tr>
                    <tr>
                        <td>Certificado Energético: </td>
                        <td>{item.CEnergetica}</td>
                    </tr>
                    <tr>
                        <td>Precio: </td>
                        <td><h3>{item.precio},00 €</h3></td>
                    </tr>
                </tbody>
            </table>
        </main>
    );
};

export default Detail;