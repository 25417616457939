import React from 'react';
import './index.scss';

const Contact = () => {
    return (
        <main className="contact">
            <div className="mapa">
                <iframe title="Contact Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.0987448690503!2d2.1473324158575027!3d41.39366187926362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a29c7de67c6f%3A0xc961546f77301e72!2sAvinguda+Diagonal%2C+441%2C+08036+Barcelona!5e0!3m2!1ses!2ses!4v1522761588118" allowFullScreen />       
            </div>
            <div className="window">
                <div className="datos">
                    <h2>Vica Real Estate</h2>
                    <p>
                        Avda. Diagonal 441, 2º <br />
                        08036 Barcelona<br />
                        Tel. 93.412.45.63<br />
                        Fax. 93.412.13.30<br />
                        <a href="mailto:info@inmovica.com">Correo Electrónico</a>
                    </p>
                </div>
            </div> 
        </main>
    );
};

export default Contact;