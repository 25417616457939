import React from 'react';
import './index.scss';

const About = () => {
    return (
        <main className="aboutus">
            <h1>Quienes somos</h1>
            <p>El <span>GRUPO VICA</span>, desde el año 1994, presta servicios a Entidades Financieras de primer orden, colaborando con sus departamentos de inmuebles en la venta de las fincas adjudicadas.</p>
            <p>Fruto de esa experiencia nace en el año 2009, <span>VICA Real Estate (VICA)</span> que es la división del grupo que ofrece a las Entidades Financieras, Fondos de Inversión y Family Offices un servicio global. <span>VICA</span> actua como plataforma de servicios entre sus clientes y sus delegaciones.</p>
            <p><span>VICA</span> ofrece una estructura interna formada por gestores de equipos comerciales, abogados, informáticos, personal administrativo y comerciales propios. Junto a esta estructura, la labor de VICA se centra en la creación, formación y control de redes de agentes.</p>
            <p><span>VICA</span>, cuenta en la actualidad con delegaciones en Barcelona, Tarragona, Lleida, Girona, Baleares, Valencia, Andalucía, Pamplona, Zaragoza, Pais Vasco, Asturias, Cantabria, La Rioja, Navarra, Toledo, Madrid, Segovia, Valladolid, Ávila y Guadalajara.</p>
        </main>
    );
};

export default About;