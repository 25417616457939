import React from 'react';
import { Link } from "react-router-dom";

import comedor from '../../img/piso/comedor.jpg'

const ItemDetail = props => {
    const {item} = props
    return (
        <div className="card">
            <div className="cardImage">
                <img src={comedor} alt="Comedor de la vivienda" />
            </div>
            <div className="cardData">
                <h4>{item.title}</h4>
                <p>{item.description}</p>
                <Link to="/inmueble/linares">Ver detalles</Link>
            </div>
        </div>
    );
};

export default ItemDetail;