import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>Av. Diagonal, 441, 2º | 08036 Barcelona | T. 934 124 563 | F 934 121 330 | <a href="/contactar/">Contacta con nosotros</a></p>
        </footer>
    );
};

export default Footer;