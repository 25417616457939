import React, { Component } from 'react';
import ItemDetail from '../../Components/ItemDetail';
import './index.scss';

class List extends Component {
    constructor(props){
        super(props);

        this.state = {
            items:[{
                id: 1,
                title: 'Piso en Linares',
                description: 'Piso en venta en Linares - Jaén, piso para reformar de 88 m2 distribuidos en salón, terraza, cocina amueblada, dos dormitorios y un cuarto de baño. Suelos de gres, ventanas de aluminio lacado en blanco, persianas de pvc. 2º piso sin ascensor. Muy bien situado con todos los servicios próximos. Visita sin compromiso.',
                habitaciones: 2,
                planta: 2,
                metros: 88,
                ascensor: false,
                baño: 1,
                terraza: true,
                balcón: true,
                tipo: '2ª mano / Reformar',
                CEnergetica: 'E (IPE no indicado)',
                precio: 39000,
           }]
        }
    }
    render() {
        const {items} = this.state;
        return (
            <main>
                {items.map(item =><ItemDetail key={item.id} item={item} />)}
            </main>
        );
    }
}

export default List;