import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from '../../img/InmovicaB.png'

const Header = () => {
    const [toggle, setToggle] = useState(false)
    return (
        <nav>
            <header className="App-header">
                <img src={Logo} alt="Inmovica" />
                <button className="menuButton" onClick={()=>setToggle(!toggle)}><i className="fas fa-bars"></i></button>
            </header>
            <ul className={`${toggle?"toggled":""}`} onClick={()=>setToggle(false)}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/inmueble/">Buscador</Link></li>
                <li><Link to="/aboutus/">Quienes somos</Link></li>
                <li><Link to="/contactar/">Contactar</Link></li>
            </ul>
        </nav>
    );
}
export default Header;