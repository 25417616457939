import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from '../Header';
import Footer from '../Footer';
import MainView from '../../Views/MainView';
import Component404 from '../../Views/Component404';
import Detail from '../../Views/Detail';
import List from '../../Views/List';
import About from '../../Views/About';
import Contact from '../../Views/Contact';
import './index.scss';


class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Switch>
              <Route exact path="/" component={()=><MainView />} />
              <Route exact path="/inmueble/" component={()=><List/>} />
              <Route exact path="/aboutus/" component={()=><About/>} />
              <Route exact path="/contactar/" component={()=><Contact/>} />
              <Route exact path="/inmueble/:id" component={()=><Detail/>} />
              <Route component={()=><Component404 />} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
