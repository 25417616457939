import React from 'react';
import './index.scss';

const MainView = () => {
    return (
        <main className="mainview">

        </main>
    );
};

export default MainView;